import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { Card } from '@mui/material';
import './preview/preview.css';
import PreviewCardHeader from './componentUtils/previewCardHeader';
import { getMatrimonialProfileById } from '../../actions/matrimonialAction';
import PreviewFamilyMember from './preview/previewFamilyMember';
import PreviewPersonal from './preview/PreviewPersonal';
import PreviewEducation from './preview/previewEducation';
import PreviewPhotoKundli from './preview/previewPhotoKundli';
import PreviewOccupation from './preview/previewOccupation';
import FormatDisplayName, { ExtractDataFromProfile } from './componentUtils/formatDisplayName';
import LoadingSpinner from '../loader';
import Button from '@mui/material/Button';

const PreviewProfile = () => {
  const [profile, setProfile] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const matrimonialId = params.get('matrimonialId');
  const showMatchingProfileBtn = params.get('showMatchBtn');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMatrimonialProfileById(matrimonialId);
        console.log('response ', response);
        if (response?.status) {
          setProfile(response?.profile);
        }
      } catch (error) {
        console.error('Error fetching matching profile:', error);
      }
    };

    fetchData();
  }, [matrimonialId]);
  const { name, gautra, subcaste } = ExtractDataFromProfile(profile);
  const displayName = FormatDisplayName(name, gautra, subcaste);

  const handleMatchProfile = () => {
    const url = `/matrimonials/foundMatches?name=${displayName}&matrimonialId=${matrimonialId}`;
    window.open(url, '_blank');
  };
  return (
    <Card>
      <div style={{ margin: '16px' }}>
        <PreviewCardHeader heading={`Profile Details For ${displayName}`} isEditButton={false} />
        {showMatchingProfileBtn !== 'false' && <Button onClick={handleMatchProfile}>View Matching Profile</Button>}
        {profile ? (
          <div style={{ display: 'flex', gap: '16px', flexDirection: 'column', marginBottom: '70px' }}>
            <PreviewPersonal profile={profile} isEditButton={false} />
            <PreviewEducation profile={profile} isEditButton={false} />
            <PreviewOccupation profile={profile} isEditButton={false} />
            <PreviewFamilyMember matrimonialId={matrimonialId} isEditButton={false} />
            <PreviewPhotoKundli matrimonialId={matrimonialId} isEditButton={false} />
          </div>
        ) : (
          <LoadingSpinner message="Loading Profile" />
        )}
      </div>
    </Card>
  );
};

export default PreviewProfile;
