import React, { useState } from 'react';
import { Menu, MenuItem, IconButton, TablePagination } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './photosStyles.css';

const ViewPhotos = ({
  photos,
  heading,
  onClose,
  showCloseBtn,
  showMoveBtn = false,
  onDeleteButtonClick,
  showDeleteBtn = false,
  showSetDefault = false,
  showMenu = true,
  defaultIndex = 0,
  handleSetAsDefault,
  handleMoveButtonClick,
  showFullScreen = false,
  isVideo = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default to 20 photos per page
  const isPdf = (url) => url.toLowerCase().endsWith('.pdf');

  const handleMenuOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setActiveMenuIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setActiveMenuIndex(null);
  };

  const handleDefaultPhotoIndexChange = (e, index) => {
    e.preventDefault();
    handleSetAsDefault(index);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleMediaClick = (media) => {
    console.log('clicked ', media);
    window.open(media, '_blank').focus(); // Open in new tab
  };

  return (
    <div className={`container ${showFullScreen ? 'fullScreenContainer' : ''}`}>
      <div className="header">
        <h2 style={{ flex: 1, color: 'black' }}>{heading}</h2>
        <p>
          {photos?.length} {isVideo ? 'videos' : 'photos'}
        </p>
        {showCloseBtn && (
          <button className="button" onClick={onClose}>
            close
          </button>
        )}
      </div>
      {photos && photos.length > 0 ? (
        <>
          <div className={`${!showFullScreen && 'photoGallery'}`}>
            {photos
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) // Get the subset of photos for the current page
              .map((media, index) => (
                <div key={index} className="photoContainer">
                  {isPdf(media) ? (
                    <iframe
                      src={`http://docs.google.com/viewer?url=${media}&embedded=true`}
                      title={`PDF-${index}`}
                      className={`pdfViewer ${showFullScreen ? 'pdfViewerFullScreen' : ''}`} // Adjust style based on full-screen prop
                    />
                  ) : isVideo ? (
                    <video src={media} controls className="photo" onClick={() => handleMediaClick(media)} />
                  ) : (
                    <img
                      src={media}
                      alt={`Media ${index}`}
                      className={`photo ${showFullScreen ? 'photoFullScreen' : ''}`} // Adjust style based on full-screen prop
                      onClick={() => handleMediaClick(media)}
                    />
                  )}
                  {showMenu && (
                    <div className="menu">
                      <IconButton onClick={(e) => handleMenuOpen(e, index)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl) && activeMenuIndex === index}
                        onClose={handleMenuClose}
                      >
                        {showDeleteBtn && (
                          <MenuItem
                            onClick={() => {
                              onDeleteButtonClick(index);
                              handleMenuClose();
                            }}
                          >
                            Delete
                          </MenuItem>
                        )}
                        {showMoveBtn && (
                          <MenuItem
                            onClick={() => {
                              handleMoveButtonClick(index);
                              handleMenuClose();
                            }}
                          >
                            Move
                          </MenuItem>
                        )}
                      </Menu>
                    </div>
                  )}
                  {showSetDefault && (
                    <>
                      <input
                        type="radio"
                        id={`photo-${index}`}
                        className="radioInput"
                        checked={index === defaultIndex}
                        onChange={(e) => handleDefaultPhotoIndexChange(e, index)}
                      />
                      <label htmlFor={`photo-${index}`} className="radioLabel">
                        {index === defaultIndex ? '✓ Default' : 'Set as Default'}
                      </label>
                    </>
                  )}
                </div>
              ))}
          </div>
          {photos.length > 20 && (
            <TablePagination
              component="div"
              count={photos.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[20, 40, 60, 80]}
              className="pagination"
            />
          )}
        </>
      ) : (
        <p>No {isVideo ? 'videos' : 'photos'} available</p>
      )}
    </div>
  );
};

export default ViewPhotos;
